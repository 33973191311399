/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {useAuth} from '../core/Auth';
import {AuthModel} from '../core/_models';
import {ApiError} from '../core/apiModels';
import {AuthApi} from '../api/auth-api';
import {AuthApiFake} from '../api/auth-api.fake';
import {AppLocator, DEFAULT_ROLE} from '../../../../app-locator';
import {} from '../../models-management/types';
import {getOrganizationByDomain, getOrganizationById} from '../../admin/api/organizations-api';
import {useBranding} from '../../app/core/BrandingProvider';

const SHOW_SIGNUP_LINK = true;
const SUPPORT_EMAIL = 'admin@vree.ai';

const api =
  AppLocator.forceUsingRealApi || process.env.NODE_ENV === 'production'
    ? new AuthApi()
    : new AuthApiFake();

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/,
      'The email you entered is not valid'
    )
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
  const {saveAuth, setCurrentUser, logout} = useAuth();
  const {organization, setOrganization} = useBranding();
  const [showPassword, setShowPassword] = useState(false);
  const [failedCount, setFailedCount] = useState(0);
  const [mtcMode, setMtcMode] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true);
        const {
          data: {access_token},
        } = await api.login(values.email, values.password);

        const auth: AuthModel = {api_token: access_token};

        saveAuth(auth);

        const {data: user} = await api.getUserByToken(auth.api_token);

        // set branding data
        const org = await getOrganizationById(user.org_id || 0);
        setOrganization(org);

        // redirect normal users if they are logging in outside main domain
        if (
          !user.org_id &&
          !['console.vree.ai', 'test.vree.ai', 'localhost'].includes(window.location.hostname)
        ) {
          setStatus('Please login via main app url, you will be redirected now!');
          setTimeout(() => {
            logout();
            window.open('https://console.vree.ai', '_self');
          }, 2500);
          return;
        }

        setCurrentUser({
          ...user,
          role_id: user.role_id ?? DEFAULT_ROLE,
          is_super_admin: !!user.is_super_admin,
          is_org_admin: !!user.is_org_admin,
        });
      } catch (error: any) {
        console.error(error);

        if (error?.response?.data?.error_code === 'ORG_UNDER_MAINTENANCE') {
          setMtcMode(true);

          setOrganization({
            maintenance_page_text: error?.response?.data?.msg,
          });

          navigate('/auth/maintenance');
        }

        saveAuth(undefined);
        setSubmitting(false);
        setLoading(false);

        setFailedCount((v) => v + 1);

        const errorResponse: ApiError | undefined = error?.response?.data;
        if (errorResponse?.msg) setStatus(errorResponse?.msg);
        else setStatus('The login detail is incorrect');
      }
    },
  });

  // RENDERING
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In</h1>
        {(!organization || !['FBX', 'FBX_TEST'].includes(organization.name || '')) && (
          <div className='text-gray-400 fw-bold fs-4'>
            New Here?{' '}
            <Link to='/auth/registration' className='link-primary fw-bolder'>
              Create an Account
            </Link>
          </div>
        )}
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* EMAIL begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert color-danger'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* PASSWORD begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Lost your password?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <div className='position-relative'>
          <input
            type={showPassword ? 'text  ' : 'password'}
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx('form-control form-control-lg form-control-solid')}
          />
          <div
            className='btn btn-icon position-absolute top-0 right-0 pt-1'
            onClick={() => setShowPassword((v) => !v)}
          >
            <i
              className={clsx(
                'fa  fs-4',
                {'fa-eye': showPassword},
                {'fa-eye-slash': !showPassword}
              )}
            ></i>
          </div>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {failedCount > 3 && (
        <div className='text-gray-700 mb-2'>
          Have an issue?{' '}
          <a
            href={`mailto:${(organization as any)?.support_email || SUPPORT_EMAIL}`}
            className='text-primary'
          >
            contact support
          </a>
        </div>
      )}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
        {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}
        {/* end::Separator */}

        {/* begin::Google link */}
        {/* <a href='#' className='disabled btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='disabled btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  );
}
