import {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {LoadingPlaceholder} from '../../../../../shared/components/LoadingPlaceholder';
import {ErrorPlaceholder} from '../../../../../shared/components/ErrorPlaceholder';
import {PageTitle} from '../../../../../_metronic/layout/core';
import {getAllSettings, setMaintenance, updateSettings} from '../../api/users-api';
import * as Yup from 'yup';
import {Settings} from '../../types';
import clsx from 'clsx';
import {FormFieldErrorMessage} from '../../../../../shared/components/FormFieldErrorMessage';
import {parseError, round} from '../../../../../shared/utils';
import {toast} from 'react-toastify';
import {Checkbox} from 'primereact/checkbox';
import {useBranding} from '../../../app/core/BrandingProvider';
import {useAuth} from '../../../auth';
import {getAllOrganizations} from '../../api/organizations-api';
import {Dropdown} from 'primereact/dropdown';
import {InputText} from 'primereact/inputtext';

const initialValues: Settings = {
  credits_initial: 0,
  ai_version: 1.0,
  ec2_termination_job_interval: 15,
};

const formSchema = Yup.object().shape({
  credits_initial: Yup.number().min(0, 'Minimum 0 credits'),
  ai_version: Yup.number().min(0, 'Minimum 0 '),
  ec2_termination_job_interval: Yup.number().min(15, 'Minimum 15 minutes'),
});

const SettingsPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [settings, setSettings] = useState<Settings>();
  const {organization} = useBranding();
  const [org, setOrg] = useState(organization);
  const [orgs, setOrgs] = useState<any>([]);
  const {currentUser} = useAuth();
  const [orgFilter, setOrgFilter] = useState<any>();

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        await updateSettings(values);
        toast.success('Settings updated successfully');
      } catch (err: any) {
        toast.error('Settings failed to update! ');
        console.error(err);

        setSubmitting(false);
        setStatus(parseError(err));
      }
    },
  });

  const reset = () => {
    settings && formik.setValues(settings, false);
  };

  const incrementVersion = () => {
    const value = (Number(formik.values.ai_version) || 0) + 0.1;
    formik.setFieldValue('ai_version', round(value, 1));
  };

  const saveMtc = async () => {
    if (org) {
      await setMaintenance({
        org_id: org.id,
        maintenance_page_text: org.maintenance_page_text,
        is_under_maintenance: org.is_under_maintenance,
      });

      toast.success('System maintenance mode updated');
    }
  };

  useEffect(() => {
    if (!currentUser?.is_super_admin) return;

    try {
      const getOrgs = async () => {
        const orgs = await getAllOrganizations();
        setOrgs([...orgs]);
      };

      getOrgs();
    } catch (error: any) {
      console.error(error);
    }
  }, [currentUser]);

  useEffect(() => {
    const getSettings = async () => {
      try {
        setLoading(true);
        const _settings = await getAllSettings();
        setSettings(_settings);
        formik.setValues(_settings);
      } catch (err) {
        setError(parseError(err));
      } finally {
        setLoading(false);
      }
    };

    if (!currentUser?.is_super_admin) return;

    getSettings();
  }, []);

  useEffect(() => {
    setOrg(organization);
  }, [organization]);

  if (loading) return <LoadingPlaceholder label='Load settings ...' />;
  if (error) return <ErrorPlaceholder error={error} />;

  return (
    <div>
      <PageTitle>System Settings</PageTitle>
      <div>
        <div className=''>
          <article className='card'>
            <header className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-dark fs-2'>Settings</span>
                <span className='text-muted mt-1 fw-semibold fs-5'>
                  Please fill in the data below
                </span>
              </h3>
            </header>

            <div className='card-body p-9 '>
              {/* Form Status */}
              {formik.status && (
                <div className='mb-5 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              {currentUser?.is_super_admin && (
                <form noValidate onSubmit={formik.handleSubmit}>
                  <fieldset className='row mb-8'>
                    <div className='col-xl-3 col-lg-4 col-sm-4 fs-5 fw-bold mt-2 mb-3'>
                      Initial Free Credits
                    </div>
                    <div className='col-xl-3 col-md-4 '>
                      <input
                        type='text'
                        className={clsx('form-control form-control-lg form-control-solid')}
                        {...formik.getFieldProps('credits_initial')}
                      />

                      {formik.touched.credits_initial && formik.errors.credits_initial && (
                        <FormFieldErrorMessage>
                          {formik.errors.credits_initial}
                        </FormFieldErrorMessage>
                      )}
                    </div>
                  </fieldset>

                  <fieldset className='row mb-8'>
                    <div className='col-xl-3 col-lg-4 col-sm-4 fs-5 fw-bold mt-2 mb-3'>
                      AI Version Number
                    </div>
                    <div className='col-xl-3 col-md-4 '>
                      <div className='input-group mb-5'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='AI Version '
                          {...formik.getFieldProps('ai_version')}
                          disabled
                        />

                        <span className='input-group-text p-0' id='aiversion'>
                          <button
                            className='btn btn-icon ripple active:bg-black-alpha-10 rounded-0 rounded-end'
                            type='reset'
                            onClick={incrementVersion}
                          >
                            <i className='fas fa-plus fs-4'></i>
                            {/* Increase */}
                          </button>
                        </span>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='row mb-8'>
                    <div className='col-xl-3 col-lg-4 col-sm-4 fs-5 fw-bold mt-2 mb-3'>
                      Interval of EC2 instances termination job
                    </div>
                    <div className='col-xl-3 col-md-4 '>
                      <input
                        type='text'
                        placeholder='in minutes'
                        className={clsx('form-control form-control-lg form-control-solid')}
                        {...formik.getFieldProps('ec2_termination_job_interval')}
                      />

                      {formik.touched.ec2_termination_job_interval &&
                        formik.errors.ec2_termination_job_interval && (
                          <FormFieldErrorMessage>
                            {formik.errors.ec2_termination_job_interval}
                          </FormFieldErrorMessage>
                        )}
                    </div>
                  </fieldset>
                  <footer className='row'>
                    <div className='col-xl-3 col-lg-4 col-sm-4'></div>
                    <div className='col-md-6'>
                      <button type='submit' className='btn btn-primary w-fit '>
                        {!formik.isSubmitting && <span>Save Settings</span>}
                        {formik.isSubmitting && (
                          <span className='indicator-progress d-block'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <button
                        className='btn btn-light-primary ms-3'
                        type='reset'
                        onClick={() => reset()}
                      >
                        Reset Changes
                      </button>
                    </div>
                  </footer>
                </form>
              )}

              <hr />

              {currentUser?.is_super_admin && (
                <fieldset className='row mb-8'>
                  <div className='col-xl-3 col-lg-4 col-sm-4 fs-5 fw-bold mt-2 mb-3'>
                    Organization:
                  </div>
                  <div className='col-xl-3 col-md-4 '>
                    <div className='input-group mb-5'>
                      <Dropdown
                        className='w-250px ms-5 border-0'
                        value={orgFilter}
                        onChange={(e: any) => {
                          setOrg(e.value);
                          setOrgFilter(e.value);
                        }}
                        options={orgs}
                        optionLabel='name'
                        placeholder={'Filter by organization'}
                        showClear
                      />
                    </div>
                  </div>
                </fieldset>
              )}

              <fieldset className='row mb-8'>
                <div className='col-xl-3 col-lg-4 col-sm-4 fs-5 fw-bold mt-2 mb-3'>
                  Enable Maintenance Mode:
                </div>
                <div className='col-xl-3 col-md-4 '>
                  <div className='input-group mb-5'>
                    {org && (
                      <input
                        type='checkbox'
                        name='xx'
                        className='fs-2'
                        checked={org?.is_under_maintenance || false}
                        onChange={(e) => setOrg({...org, is_under_maintenance: e.target.checked})}
                        id=''
                      />
                    )}
                  </div>
                </div>
              </fieldset>

              <fieldset className='row mb-8'>
                <div className='col-xl-3 col-lg-4 col-sm-4 fs-5 fw-bold mt-2 mb-3'>
                  Maintenance Message:
                </div>
                <div className='col-xl-3 col-md-4 '>
                  {/* <InputText
                    value={org?.maintenance_page_text}
                    onChange={(e) => setOrg({...org, maintenance_page_text: e.target.value})}
                  /> */}
                  <input
                    placeholder='Type'
                    type='text'
                    name='maintenance_page_text'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    autoComplete='off'
                    value={org?.maintenance_page_text || ''}
                    disabled={formik.isSubmitting}
                    onChange={(e) => setOrg({...org, maintenance_page_text: e.target.value})}
                  />

                  {/* {org?.maintenance_page_text} */}
                </div>
              </fieldset>

              <button onClick={saveMtc} className='btn btn-primary w-fit '>
                {!formik.isSubmitting && <span>Save Maintenance Mode</span>}
                {formik.isSubmitting && (
                  <span className='indicator-progress d-block'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
