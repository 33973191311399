import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {createModel} from '../../../../models-management/api/models-api.fake';
import {createModelAdmin} from '../../../api/users-api';
import clsx from 'clsx';
import {useNavigate} from 'react-router-dom';
import useOrgStore from '../organization.store';
import {updateOrganization} from '../../../api/organizations-api';
import {Checkbox} from 'primereact/checkbox';

const initialModel = {
  name: undefined,
  domain: undefined,
};
const newModelSchema = Yup.object().shape({
  // name: Yup.string().required('Name is required'),
});

export function OrganizationForm() {
  const navigate = useNavigate();
  const {org, setOrg} = useOrgStore();

  const clearForm = () => {
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: org || initialModel,
    enableReinitialize: true,
    validationSchema: newModelSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true);
      setStatus();
      try {
        //api submit
        await updateOrganization({...org, ...values});

        toast.success('Saved!');

        // navigate();

        // clearForm();
      } catch (error: any) {
        console.error(error);
        setStatus(error?.response?.data?.msg ?? 'Failed to submit');
        toast.error('Submit Failed');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <form className='form' onSubmit={formik.handleSubmit} id='form_sad' noValidate>
      <div className='card pt-4 mb-6 mb-xl-9'>
        <div className='card-header border-0'>
          <div className='card-title'>
            <h2>Basic Info</h2>
          </div>
          <div className='card-toolbar'>
            <button type='submit' className='btn btn-sm btn-light-primary'>
              {!formik.isSubmitting && (
                <>
                  {' '}
                  <i className='ki-duotone ki-check-circle fs-3'>
                    <span className='path1' />
                    <span className='path2' />
                  </i>
                  Save Changes
                </>
              )}
              {formik.isSubmitting && (
                <span className='indicator-progress d-block'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
        <div className='card-body py-0'>
          {formik.status && (
            <div className='mb-lg-10 mb-sm-5 alert alert-danger mw-500px m-auto'>
              <div className='alert-text font-weight-bold capitalized'>{formik.status}</div>
            </div>
          )}

          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3  fw-bold fs-6 mb-2'>Name</label>

            <div className='col-lg-7 col-xl-5'>
              <span className='fw-bold pt-3'>{org?.name}</span>
              {/* <input
                placeholder='Type name'
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.name && formik.errors.name},
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                autoComplete='off'
                disabled={true || formik.isSubmitting}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )} */}
            </div>
          </fieldset>

          <fieldset className='row mb-7'>
            <label className='py-2 col-lg-3 required fw-bold fs-6 mb-2'>Domain</label>

            <div className='col-lg-7 col-xl-5'>
              <input
                placeholder='Type url'
                {...formik.getFieldProps('domain')}
                type='text'
                name='domain'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.domain && formik.errors.domain},
                  {
                    'is-valid': formik.touched.domain && !formik.errors.domain,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.domain && formik.errors.domain && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.domain}</span>
                  </div>
                </div>
              )}
            </div>
          </fieldset>
        </div>
      </div>
    </form>
  );
}
